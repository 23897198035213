import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Header = styled.header`
  text-align: center;
`
const Content = styled.div`
  text-align: center;
`

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <Header>
                <h2>{props.children}</h2>
            </Header>
            <Content>
              <ul className="actions">
                <li><AnchorLink href="#contact" className="button special green">Submit project</AnchorLink></li>
                {/*<li><AnchorLink href="#contact" className="button">Devs</AnchorLink></li>*/}
              </ul>
            </Content>
        </div>
    </section>
)

export default Banner
