import React from 'react'
import SimpleLineIcon from 'react-simple-line-icons';
import styled from 'styled-components'
import 'isomorphic-fetch'

const ContactHeading = styled.h3`
  display: flex;
  align-items: center;
  
  div {
    margin-right: .4em;
  }
`

const ConvertFormToJSON = (formData) => {
  var getJsonFromFormData = (formData) => {
    var json = {};
    for (item of formData.keys()){
      json[item] = formData.get(item);
    }
    return json;
  }
  return getJsonFromFormData(formData);
}

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    var error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

const parseJSON = (response) => {
  return response.json()
}

/**
 * Checks that an element has a non-empty `name` and `value` property.
 * @param  {Element} element  the element to check
 * @return {Bool}             true if the element is an input, false if not
 */
const isValidElement = element => {
  return element.name && element.value;
};

/**
 * Checks if an element’s value can be saved (e.g. not an unselected checkbox).
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the value should be added, false if not
 */
const isValidValue = element => {
  return (!['checkbox', 'radio'].includes(element.type) || element.checked);
};

/**
 * Checks if an input is a checkbox, because checkboxes allow multiple values.
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the element is a checkbox, false if not
 */
const isCheckbox = element => element.type === 'checkbox';

/**
 * Checks if an input is a `select` with the `multiple` attribute.
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the element is a multiselect, false if not
 */
const isMultiSelect = element => element.options && element.multiple;

/**
 * Retrieves the selected options from a multi-select as an array.
 * @param  {HTMLOptionsCollection} options  the options for the select
 * @return {Array}                          an array of selected option values
 */
const getSelectValues = options => [].reduce.call(options, (values, option) => {
  return option.selected ? values.concat(option.value) : values;
}, []);

/**
 * Retrieves input data from a form and returns it as a JSON object.
 * @param  {HTMLFormControlsCollection} elements  the form elements
 * @return {Object}                               form data as an object literal
 */
const formToJSON = elements => [].reduce.call(elements, (data, element) => {

  // Make sure the element has the required properties and should be added.
  if (isValidElement(element) && isValidValue(element)) {

    // eslint-disable-next-line no-console
    // console.log('element.value: ', element.value);
    /*
     * Some fields allow for more than one value, so we need to check if this
     * is one of those fields and, if so, store the values as an array.
     */
    if (isCheckbox(element)) {
      data[element.name] = (data[element.name] || []).concat(element.value).replace(/\W/g, '');
    } else if (isMultiSelect(element)) {
      data[element.name] = getSelectValues(element).replace(/\W/g, '');
    } else {
      const val = element.value;
      const name = element.name;
      data[name] = val.trim().replace("'","");
    }
  }

  return data;
}, {});

const onSubmit = (e) => {
  e.preventDefault();
  const button = document.getElementById('submit-button');
  button.disabled = true;
  button.value = "Sending..."

  const form = document.getElementById('contact-form');
  form.disabled = true;
  const data = formToJSON(form.elements);
  data.formName = 'project-submit';
  // eslint-disable-next-line no-console
  // console.log('data: ', data);

  fetch('https://sjj9vil7ph.execute-api.us-west-1.amazonaws.com/prod/hiredevs', {
    method: 'POST',
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  }).then(checkStatus)
    .then(parseJSON)
    .then(function(data) {
      window.location.href = "./thanks"
    }).catch(function(error) {
    // eslint-disable-next-line no-console
    console.log('request failed', error);
  });
}

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <form method="post" id="contact-form" onSubmit={onSubmit} name="project-submit" action="https://sjj9vil7ph.execute-api.us-west-1.amazonaws.com/prod/hiredevs">
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input required type="text" name="name" id="name" />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input required type="email" name="_replyto" id="email" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea required name="comments" id="comments" rows="6"></textarea>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" id="submit-button" value="Send Message" className="special" /></li>
                    </ul>
                </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <ContactHeading><SimpleLineIcon name="envelope" size="large" /> Email</ContactHeading>
                        <a href='&#109;ailto&#58;%68%65ll&#111;%4&#48;hir&#37;65dev%&#55;&#51;&#46;c%6&#70;&#109;'>hel&#108;o&#64;hirede&#118;&#115;&#46;com</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <ContactHeading><SimpleLineIcon name="globe" size="large" /> San Francisco</ContactHeading>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
