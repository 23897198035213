import React from 'react'
import Fade from 'react-reveal/Fade';
import Layout from '../components/layout'
import Banner from '../components/Banner'
import SimpleLineIcon from 'react-simple-line-icons';
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Contact from '../components/Contact'
import Seo from '../components/Seo'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import TextLoop from "react-text-loop";
import AWSLogo from '../assets/images/3rdpartylogos/amazon-web-services-logo.png'
import SanityLogo from '../assets/images/3rdpartylogos/sanity-logo.png'
import OpenAILogo from '../assets/images/3rdpartylogos/openai-logo.png'
import NodeJSLogo from '../assets/images/3rdpartylogos/nodejs-logo.png'
import ReactLogo from '../assets/images/3rdpartylogos/react-logo.png'
import ServerlessLogo from '../assets/images/3rdpartylogos/serverless-logo.png'

const IconWrapper = styled.div`
  text-align: center;
  .icon-bulb, .icon-wrench, .icon-rocket {
    font-size: 45px !important;
  }
`

const IconWrapperSmall = styled.span`
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  .icon-fire {
    color: white;
  }
`

const IconWrapperAlt = styled.div`
  .icon-layers, .icon-screen-desktop, .icon-anchor, .icon-people, .icon-basket-loaded {
    font-size: 65px !important;
    color: #52d3aa;
  }
  margin: 2em 0 1em;
`

const ServicesSection = styled.section`

  overflow: hidden;
  position: relative;

  @media screen and (max-width: 736px) {
    text-align: center;

    header {
    margin: 0 auto;
    }
  }


  .inner-content {
    margin: 0 0 2em 0;
  }

  &::before, &::after {
    //position: absolute;
    transform: rotate(-1.7deg);
    -ms-transform: rotate(-1.7deg);
    -webkit-transform: rotate(-1.7deg);
    -o-transform: rotate(-1.7deg);
    -moz-transform: rotate(-1.7deg);
    background-color: rgb(36, 41, 67);
    content: "";
    z-index: 3;
    display: inline-block;
    height: 70px;
    padding: 0;
    width: 150vw;
  }

  &::before {
    position: absolute;
    top: 0;
    margin-top: -60px;
  }

  &::after {
    top: 0;
    margin-bottom: -35px;
  }
`

const GridWrapper = styled.section`

  &.intro-grid {
   margin-bottom: 4em !important;
  }

  h2 {
    margin-top: .5em;
    margin-bottom: 0;
  }
`

const ProjectListingTable = styled.div`
  margin: 4em 0;

  @media screen and (max-width: 40em) {
    table tr {
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      border-bottom: 1px solid rgba(255, 255, 255, .4) !important;
    }
  }
`

const GridItem = styled.div`
  position: relative;

  p {
    margin-bottom: 0;
  }
`

const AboutSection = styled.section`
  background-color: #52d3aa;
  /* IE9, iOS 3.2+ */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzZjk1ZWEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzUyZDNhYSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image: -webkit-gradient(linear, 0% 0%, 100% 100%, color-stop(0, #3f95ea), color-stop(1, #52d3aa));
  /* Android 2.3 */
  background-image: -webkit-repeating-linear-gradient(top left, #3f95ea 0%, #52d3aa 100%);
  /* IE10+ */
  background-image: repeating-linear-gradient(to bottom right, #3f95ea 0%, #52d3aa 100%);
  background-image: -ms-repeating-linear-gradient(top left, #3f95ea 0%, #52d3aa 100%);
  overflow: hidden;
  position: relative;

  &::before, &::after {
    //position: absolute;
    transform: rotate(-1.7deg);
    -ms-transform: rotate(-1.7deg);
    -webkit-transform: rotate(-1.7deg);
    -o-transform: rotate(-1.7deg);
    -moz-transform: rotate(-1.7deg);
    background-color: rgb(36, 41, 67);
    content: "";
    z-index: 3;
    display: inline-block;
    height: 70px;
    padding: 0;
    width: 150vw;
  }

  &::before {
    top: 0;
    margin-top: -60px;
    left: -15px;
    position: absolute;
  }
  &::after {
    top: 0;
    margin-bottom: -45px;
    left: -15px;
  }

 .inner-content {
    text-align: center;
    max-width: 800px;
    margin: 4em auto;
 }

 p {
  font-size: 1.2em;
 }
`

const AboutHeader = styled.header`
  margin: 0 auto;
`

const IntroAnimatedText = styled.span`
  color: #52d3aa;
`

class HomeIndex extends React.Component {
    render() {
        return (
            <Layout>
                <Seo />
                <Banner>
                  Hire top tier software developers for your
                  {" "}<TextLoop>
                    <IntroAnimatedText>Serverless</IntroAnimatedText>
                    <IntroAnimatedText>AI Chat</IntroAnimatedText>
                    <IntroAnimatedText>JAMstack</IntroAnimatedText>
                    <IntroAnimatedText>Marketing</IntroAnimatedText>
                    <IntroAnimatedText>Website</IntroAnimatedText>
                    <IntroAnimatedText>Mobile</IntroAnimatedText>
                  </TextLoop>{" "}
                  project

                </Banner>
                <div id="main">
                  <div className="inner inner-first">
                    <Fade>
                      <GridWrapper className="grid-wrapper intro-grid">
                        <GridItem className="col-4 grid-item">
                          <IconWrapper><SimpleLineIcon name="bulb" /></IconWrapper>
                          <h2>Plan</h2>
                          <p>Our highly skilled engineering project managers will plan out the best serverless solution</p>
                        </GridItem>
                        <GridItem className="col-4 grid-item">
                          <IconWrapper><SimpleLineIcon name="wrench" /></IconWrapper>
                          <h2>Develop</h2>
                          <p>Our Top Tier developers will rapidly code your project with modern cloud based serverless and AI technology</p>
                        </GridItem>
                        <GridItem className="col-4 grid-item">
                          <IconWrapper><SimpleLineIcon name="rocket" /></IconWrapper>
                          <h2>Launch</h2>
                          <p>Together we will reach project success through an agile and iterative development process with a focus on quality, usability and security</p>
                        </GridItem>
                      </GridWrapper>
                    </Fade>
                  </div>
                  <AboutSection id="about">
                    <div className="inner">
                      <div className="inner-content">
                          <Fade>
                            <AboutHeader className="major">
                              <h2>About Hire Devs</h2>
                            </AboutHeader>
                          </Fade>
                          <Fade>
                            <p>
                              Our team is staffed with <strong>experienced software engineers</strong> who are experts in <a href="https://jamstack.org" rel="noopener noreferrer"  target="_blank"><strong>JAMstack</strong></a> and <a href="https://en.wikipedia.org/wiki/Serverless_computing" rel="noopener noreferrer" target="_blank"><strong>Serverless</strong></a> technologies.
                              Every developer on our team goes through a rigorous testing process and has experience at top tier companies and startups.
                            </p>
                            <p>
                              Hire Devs will plan and implement the best software development solution for your business or project and do whatever it takes to make sure we reach <strong>Project Success</strong>.
                            </p>
                          </Fade>
                      </div>
                    </div>
                  </AboutSection>
                  <ServicesSection id="services">
                    <div className="inner">
                      <div className="inner-content">
                        <header className="major">
                          <h2>Services</h2>
                        </header>
                        <Fade>
                          <GridWrapper className="grid-wrapper">
                            <GridItem className="col-6 grid-item">
                              <IconWrapperAlt><SimpleLineIcon name="screen-desktop" /></IconWrapperAlt>
                              <h4>Website and App development using JAMStack methodologies</h4>
                              <p>Modern websites and apps need to be very fast and secure without exceptions. JAMStack is secure and fast out of the box.</p>
                            </GridItem>
                            <GridItem className="col-6 grid-item">
                              <IconWrapperAlt><SimpleLineIcon name="basket-loaded" /></IconWrapperAlt>
                              <h4>Serverless CMS & E-commerce Platforms</h4>
                              <p>A CMS is a common requirement for any site to minimize the back and forth with developers and editors. We have experience with all the latest headless and serverless CMS technologies.</p>
                            </GridItem>
                            <GridItem className="col-6 grid-item">
                              <IconWrapperAlt><SimpleLineIcon name="layers" /></IconWrapperAlt>
                              <h4>AI Chat Development</h4>
                              <p>Hire Devs builds support agents and internal knowledge base chat bots using OpenAI and open source LLMs such as Mistral or Llama models.</p>
                            </GridItem>
                            <GridItem className="col-6 grid-item">
                              <IconWrapperAlt><SimpleLineIcon name="people" /></IconWrapperAlt>
                              <h4>Engineering QA and Project Managers</h4>
                              <p>Every project needs someone to manage engineers and code. In addition to top tier developers we also include engineering project managers and QA professionals to ensure Project Success</p>
                            </GridItem>
                          </GridWrapper>
                        </Fade>
                      </div>
                    </div>
                  </ServicesSection>
                  <AboutSection id="project-list">
                    <div className="inner">
                      <div className="inner-content">
                        <AboutHeader className="major">
                          <h2>Active Projects</h2>
                        </AboutHeader>
                        <Fade>
                          <ProjectListingTable>
                            <Table>
                              <Thead>
                              <Tr>
                                <Th>Project</Th>
                                <Th>Summary</Th>
                                <Th>Budget</Th>
                              </Tr>
                              </Thead>
                              <Tbody>
                              <Tr>
                                <Td>Website upgrade</Td>
                                <Td>Convert legacy Wordpress site to JAMstack and Headless CMS</Td>
                                <Td>$8-15k</Td>
                              </Tr>
                              <Tr>
                                <Td>Restaurant website</Td>
                                <Td>Headless CMS with menu and checkout from scratch.</Td>
                                <Td>$30-50k</Td>
                              </Tr>
                              <Tr>
                                <Td>Serverless api gateway</Td>
                                <Td>Lambda based API for enterprise CRM sales integration</Td>
                                <Td>$10k</Td>
                              </Tr>
                              <Tr>
                                <Td>AI chat support bot</Td>
                                <Td>Chat support bot based on OpenAI LLM</Td>
                                <Td>$12k</Td>
                              </Tr>
                              <Tr>
                                <Td><IconWrapperSmall><SimpleLineIcon name="fire" /></IconWrapperSmall>Your project</Td>
                                <Td>Successful launch and happy stakeholders</Td>
                                <Td>TBD</Td>
                              </Tr>
                              </Tbody>
                            </Table>
                          </ProjectListingTable>
                        </Fade>
                        <ul className="actions">
                          <li><AnchorLink href="#contact" className="button special">Submit your project</AnchorLink></li>
                        </ul>
                      </div>
                    </div>
                  </AboutSection>
                  <ServicesSection>
                    <div className="inner">
                      <div className="inner-content">
                        <header className="major">
                          <h2>Technologies</h2>
                        </header>
                        <Fade>
                          <GridWrapper className="grid-wrapper logo-grid-wrapper">
                            <GridItem className="col-2 grid-item">
                              <a href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">
                                <img src={ReactLogo} alt="React Logo" />
                              </a>
                            </GridItem>
                            <GridItem className="col-2 grid-item">
                              <a href="https://sanity.io/" rel="noopener noreferrer" target="_blank">
                                <img src={SanityLogo} alt="Sanity CMS Logo" />
                              </a>
                            </GridItem>
                            <GridItem className="col-2 grid-item">
                              <a href="https://www.openai.com/" rel="noopener noreferrer" target="_blank">
                                <img src={OpenAILogo} alt="OpenAI Logo" />
                              </a>
                            </GridItem>
                            <GridItem className="col-2 grid-item">
                              <a href="https://aws.amazon.com/" rel="noopener noreferrer" target="_blank">
                                <img src={AWSLogo} alt="AWS Logo" />
                              </a>
                            </GridItem>
                            <GridItem className="col-2 grid-item">
                              <a href="https://serverless.com/" rel="noopener noreferrer" target="_blank">
                                <img src={ServerlessLogo} alt="Serverless Logo" />
                              </a>
                            </GridItem>
                            <GridItem className="col-2 grid-item">
                              <a href="https://nodejs.org/en/" rel="noopener noreferrer" target="_blank">
                                <img src={NodeJSLogo} alt="Node JS Logo" />
                              </a>
                            </GridItem>
                          </GridWrapper>
                        </Fade>
                      </div>
                    </div>
                  </ServicesSection>
                </div>
              <Contact />
            </Layout>
        )
    }
}

export default HomeIndex
