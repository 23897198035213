import React from "react";
import Helmet from 'react-helmet'
import favicon5757 from '../assets/images/favicons/apple-icon-57x57.png'
import favicon114114 from '../assets/images/favicons/apple-icon-114x114.png'
import favicon7272 from '../assets/images/favicons/apple-icon-72x72.png'
import favicon144144 from '../assets/images/favicons/apple-icon-144x144.png'
import favicon6060 from '../assets/images/favicons/apple-icon-60x60.png'
import favicon120120 from '../assets/images/favicons/apple-icon-120x120.png'
import favicon7676 from '../assets/images/favicons/apple-icon-76x76.png'
import favicon152152 from '../assets/images/favicons/apple-icon-152x152.png'
import favicon9696 from '../assets/images/favicons/favicon-96x96.png'
import favicon3232 from '../assets/images/favicons/favicon-32x32.png'
import favicon1616 from '../assets/images/favicons/favicon-16x16.png'
import faviconms144144 from '../assets/images/favicons/ms-icon-144x144.png'
import faviconms150150 from '../assets/images/favicons/ms-icon-150x150.png'
import faviconms310310 from '../assets/images/favicons/ms-icon-310x310.png'

const Seo = ({ close }) => (
  <Helmet
    title="Hire Devs - Hire Top Tier JAMstack Software Developers"
    meta={[
      { name: 'description', content: 'List your project and hire top tier JAMstack software developers' },
    ]}
  >
    <link rel="apple-touch-icon-precomposed" sizes="57x57" href={favicon5757} />
    <link rel="apple-touch-icon-precomposed" sizes="114x114" href={favicon114114} />
    <link rel="apple-touch-icon-precomposed" sizes="72x72" href={favicon7272} />
    <link rel="apple-touch-icon-precomposed" sizes="144x144" href={favicon144144} />
    <link rel="apple-touch-icon-precomposed" sizes="60x60" href={favicon6060} />
    <link rel="apple-touch-icon-precomposed" sizes="120x120" href={favicon120120} />
    <link rel="apple-touch-icon-precomposed" sizes="76x76" href={favicon7676} />
    <link rel="apple-touch-icon-precomposed" sizes="152x152" href={favicon152152} />
    <link rel="icon" type="image/png" href={favicon9696} sizes="96x96" />
    <link rel="icon" type="image/png" href={favicon3232} sizes="32x32" />
    <link rel="icon" type="image/png" href={favicon1616} sizes="16x16" />
    <meta name="application-name" content="&nbsp;"/>
    <meta name="msapplication-TileColor" content="#FFFFFF" />
    <meta name="msapplication-TileImage" content={faviconms144144} />
    <meta name="msapplication-square150x150logo" content={faviconms150150} />
    <meta name="msapplication-square310x310logo" content={faviconms310310} />
  </Helmet>
);

export default Seo;
